import React, { useEffect } from "react";
import { Button, Tooltip } from "@nextui-org/react";
import { FaDoorOpen } from "react-icons/fa";

const Auth = (props) => {
  const user = props.user;
  const avatar = props.avatar;
  const AUTH_URL = process.env.REACT_APP_AUTH_URL;

  return (
    <div>
      {user ? (
        <div>
          <span>{user.username}</span>
          {avatar ? <img className="max-h-[32px] inline ml-4" src={props.avatar} /> : <span></span>}
        </div>
      ) : (
        <div>
          <Tooltip
            content="log in with discord"
            color="secondary"
            placement="bottom">
            <Button isIconOnly color="secondary">
              <a href={`${AUTH_URL}`}>
                <FaDoorOpen />
              </a>
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Auth;
