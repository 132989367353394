import React, { useEffect, useState } from "react";
import { Button } from "@nextui-org/button";
import { Input } from "@nextui-org/input";
import { Checkbox } from "@nextui-org/checkbox";
import { CircularProgress } from "@nextui-org/progress";
import {
  Card,
  CardBody,
  CardHeader,
  Tooltip,
  Tab,
  Tabs,
} from "@nextui-org/react";

import { FaTrash, FaSave, FaPlus } from "react-icons/fa";

const Form = (props) => {
  // const [configData, setConfigData] = useState(null)
  const [formState, setFormState] = useState({});
  // NOTE: configData in this context is just the LOADED config, not the
  // entire set of data.
  var configData = props.config.loadedConfig.config;
  const configName = props.configName;
  console.log(configData);
  const updateConfig = props.updateConfig;

  useEffect(() => {
    if (configData) {
      setFormState(configData);
      console.log("config data changed - re-rendering");
      console.log(configData);
    }
  }, [configData]);

  const handleChange = (e, path = []) => {
    const { name, value, type, checked } = e.target;
    const newState = { ...formState };
    let current = newState;

    // Traverse the path to the right location
    for (let i = 0; i < path.length; i++) {
      current = current[path[i]];
    }

    // Set the value at the right location
    if (type === "checkbox") {
      current[name] = checked;
    } else {
      current[name] = value;
    }

    setFormState(newState);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getValueFromPath = (obj, path) => {
    return path.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : ""),
      obj
    );
  };

  const addArrayElement = (path, key) => {
    const newState = { ...configData };
    let current = newState;

    var arrayField = getValueFromPath(configData, path); // this gets the OBJECT that the ARRAY is ON!
    var arrayTarget = arrayField[key]; // this is the value we need to add a key too
    console.log("array target: ");
    console.log(arrayTarget);

    let fieldToDuplicate = null;
    if (typeof arrayTarget[0] === "object" && !Array.isArray(arrayTarget[0])) {
      console.log("array target is an object");
      fieldToDuplicate = { ...arrayTarget[0] };
    } else if (typeof arrayTarget[0] === "string") {
      console.log("array target are strings");
      fieldToDuplicate = arrayTarget[0];
    } else if (arrayTarget.length == 0) {
      // array is an empty one...let's assume it's
      // a string array!
      fieldToDuplicate = "";
    }

    console.log("field to duplicate: ");
    console.log(fieldToDuplicate);

    // NOTE: I DID IT! IT'S WORKING!!!!!!!!!!
    path.forEach((pathPart) => {
      console.log("key that we are going to push a value to:");
      console.log(current);
      current = current[pathPart];
    });

    // set the new field to be added to an empty value based on it's field type.
    if (typeof fieldToDuplicate === "string") {
      // If the fieldToDuplicate is a string, just set an empty string to the next field.
      fieldToDuplicate = "";
    } else if (typeof fieldToDuplicate === "object") {
      // the field to be duplicated is an object. Generate a default blank one.
      // iterate over the object field to be duplicated.
      // check each field's typeOf and set it to something default. Cool B)
      for (const [key, value] of Object.entries(fieldToDuplicate)) {
        if (typeof value === "string") {
          fieldToDuplicate[key] = "";
        } else if (typeof value === "number") {
          fieldToDuplicate[key] = 0;
        } else if (typeof value === "boolean") {
          fieldToDuplicate[key] = false;
        }
      }
    }

    // Push the field we just generated to the correct key in the config object
    current[key].push(fieldToDuplicate);
    updateConfig(newState);
    setFormState(newState);
  };

  const removeArrayElement = (path, key, index) => {
    console.log(path);
    const newState = { ...configData };
    let current = newState;

    if (path.length > 0) {
      // traverse the path tree to get to the nested node,
      // if required.
      path.forEach((pathPart) => {
        console.log("key that we are going to push a value to:");
        console.log(current);
        current = current[pathPart];
      });
    } else {
      // splice the key once we're flat
      console.log(current[key][index]);
      current[key].splice(index, 1);
    }

    // update form data/config data
    updateConfig(current);
    setFormState(current);
  };

  const handleSubmit = () => {
    console.log(formState);
    setNewConfig(configName, formState);
  };

  const setNewConfig = async (configName, config) => {
    console.log("sending new config to Athena");
    const URL = process.env.REACT_APP_URL;

    // build request to send to Athena
    const requestHeaders = new Headers();
    requestHeaders.append("Content-Type", "application/json");

    const resBody = {
      configName: configName,
      config: {
        config: config,
      },
    };

    const requestOptions = {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify(resBody),
      redirect: "follow",
    };

    const response = await fetch(`${URL}/configset`, requestOptions);
    const result = await response.text();
    console.log(result);
  };

  const renderInput = (key, value, path = []) => {
    const fullPath = [...path, key];
    const currentValue = getValueFromPath(formState, fullPath);

    if (typeof value === "string") {
      return (
        <Input
          type="text"
          className="min-w-[100px] max-w-[250px] p-2 mx-4"
          name={key}
          value={currentValue}
          onChange={(e) => handleChange(e, path)}
        />
      );
    } else if (typeof value === "number") {
      return (
        <Input
          type="number"
          className="min-w-[50px] max-w-[93px] p-2 mx-4"
          name={key}
          value={currentValue}
          onChange={(e) => handleChange(e, path)}
        />
      );
    } else if (typeof value === "boolean") {
      return (
        <Input
          className="max-w-[50px] p-2 mx-4"
          type="checkbox"
          name={key}
          checked={currentValue}
          onChange={(e) => handleChange(e, path)}
        />
      );
    } else if (Array.isArray(value)) {
      return (
        // Render the actual input field for the given array
        <div className="border-l-8 rounded mt-3 ml-2 pl-4 py-2 mb-3">
          {value.map((item, index) => (
            <div className="flex flex-col mt-4" key={index}>
              <label className="ml-4 font-bold text-xl">
                {`${key}`}{" "}
                <span className="text-xs relative bottom-2 text-primary">
                  array key {index}
                </span>
              </label>
              {renderInput(index, item, fullPath)}
              <div className="flex flex-col mb-4 items-end">
                {/* Render the button to delete an array field */}
                <Tooltip
                  color="secondary"
                  placement="bottom"
                  content="Delete array item"
                >
                  <Button
                    onClick={() => removeArrayElement(path, key, index)}
                    isIconOnly
                    className="mx-2"
                    color="danger"
                    aria-label="Like"
                  >
                    <FaTrash />
                  </Button>
                </Tooltip>
              </div>
            </div>
          ))}
          {/* Render the button to add an array field */}
          <div className="my-4">
            <Tooltip
              color="secondary"
              placement="bottom"
              content="add new array item"
            >
              <Button
                onClick={() => addArrayElement(path, key)}
                isIconOnly
                className="mx-2"
                color="primary"
                aria-label="Like"
              >
                <FaPlus />
              </Button>
            </Tooltip>
          </div>
        </div>
      );
    } else if (typeof value === "object") {
      return Object.keys(value).map((nestedKey) => (
        <div className="ml-6 font-bold text-xl" key={nestedKey}>
          <label>{`${nestedKey}`}</label>
          {renderInput(nestedKey, value[nestedKey], fullPath)}
        </div>
      ));
    }
  };

  if (!configData)
    return (
      <div>
        <CircularProgress aria-label="Loading..." />
      </div>
    );

  return (
    <div className="dark text-foreground bg-background mt-4">
      <form className="flex w-full justify-center">
        <Tabs color="primary" isVertical={false} className="max-w-[98vw] md:max-w-[75vw]">
          {Object.keys(configData).map((key) => (
            <Tab key={key} title={key} className="flex justify-center">
              <Card className="p4 w-full" key={key}>
                <CardBody>
                  <CardHeader>
                    <label className="font-bold text-2xl">
                      {capitalizeFirstLetter(key.replaceAll("_", " "))}:
                    </label>
                  </CardHeader>
                  {renderInput(key, configData[key])}
                </CardBody>
              </Card>
            </Tab>
          ))}
        </Tabs>
      </form>
      <Tooltip
        color="secondary"
        placement="left"
        content="Save current configuration">
        <Button
          color="secondary"
          className="m-4 fixed bottom-20 right-6"
          onClick={handleSubmit}
          isIconOnly
        >
          <FaSave />
        </Button>
      </Tooltip>
    </div>
  );
};

export default Form;
