import React, { useEffect, useState } from 'react';
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button} from "@nextui-org/react";
import logo from './images/logo.PNG'
import Auth from './Auth'

const Nav = (props) => {
  const user = props.user
  const [ avatar, setAvatar ] = useState(null)

  const getAvatar = async () => {
    if (user) {
      const avatarReq = await fetch(`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`)
      var avatar = await avatarReq.blob()
      avatar = URL.createObjectURL(avatar)
      setAvatar(avatar)
      console.log(avatar)
    }
  }

  useEffect(() => {
    getAvatar();
  }, [user])

  return (
    <Navbar>
      <NavbarBrand>
        <img className='h-[64px]' src={logo} />
        <p className="font-bold bg-gradient-to-br from-green-400 to-green-600 bg-clip-text text-transparent text-xl">Athena Dash</p>
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>
          <Link href="#" aria-current="page">
            Edit Configs
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link href="#" color="foreground">
            Members
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" href="#">
            Functions
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        <Auth user={props.user} avatar={avatar} />
      </NavbarContent>
    </Navbar>
  )
}

export default Nav;