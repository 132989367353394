import React, { useEffect, useState } from "react";
import { CircularProgress } from "@nextui-org/progress";
import {
  NextUIProvider,
  Button,
  Listbox,
  ListboxItem,
  Tooltip,
} from "@nextui-org/react";
import Nav from "./Nav";
import Form from "./Form";
import "./App.css";
import { FaBars } from "react-icons/fa";

const ListboxWrapper = ({ children }) => (
  <div className="w-full max-w-[260px] backdrop-blur-md flex px-1 py-2 rounded-small">
    {children}
  </div>
);

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function App() {
  const [configData, setConfigData] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [sideBarOpen, setSidebarOpen] = useState(true);
  const [user, setUser] = useState(null);

  const getUserToken = async () => {
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const [accessToken, tokenType] = [
      fragment.get("access_token"),
      fragment.get("token_type"),
    ];

    if (accessToken) {
      fetch("https://discord.com/api/users/@me", {
        headers: {
          authorization: `${tokenType} ${accessToken}`,
        },
      })
        .then((result) => result.json())
        .then((response) => {
          console.log("auth response");
          console.log(response);
          setUser(response);
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    getUserToken();
  }, []);

  useEffect(() => {
    if (user) {
      getConfigFileList();
    }
  }, [user]);

  useEffect(() => {
    console.log(sideBarOpen);
  });

  useEffect(() => {
    console.log(`selected config ${selectedConfig}`);
  }, [selectedConfig]);

  const updateConfigKey = (config) => {
    console.log("updating configData...");
    const newConfig = configData;
    newConfig[selectedConfig].loadedConfig.config = config;

    console.log(newConfig);
    setConfigData(newConfig);
  };

  const getConfigFileList = async () => {
    console.log("sending GET request to Athena");
    var result;

    try {
      const URL = process.env.REACT_APP_URL;
      console.log(URL);
      const response = await fetch(`${URL}/configs`);
      result = await response.json();
    } catch (err) {
      console.log(err);
    }

    setConfigData(result);
  };

  return (
    <NextUIProvider
      id="main-element"
      className="dark text-foreground bg-background"
    >
      <Nav user={user} />
      <div id="content-container" className="">
        <div
          id="sidebar-container"
          className={`absolute m-0 md:flex z-10 rounded-md transition ease-in-out ${
            sideBarOpen ? "translate-x-[-200px]" : "translate-x-0"
          } md:mr-6 md:ml-2 mt-2 backdrop-blur-lg`}
        >
          <ListboxWrapper>
            {!user ? (
              <div>Please login.</div>
            ) : (
              <Listbox
                aria-label="choose a config to edit"
                selectionMode="single"
                color="primary"
                shouldHighlightOnFocus={true}
                onAction={(key) => setSelectedConfig(key)}
              >
                {configData && 
                    configData.map((configObject, index) => (
                      <ListboxItem className="p-2 font-bold" key={index}>
                        {capitalizeFirstLetter(configObject.name)}
                      </ListboxItem>
                    ))
                }
              </Listbox>
            )}
          </ListboxWrapper>
        </div>
        <div id="config-form-container" className="">
          {selectedConfig && (
            <Form
              config={configData[selectedConfig]}
              configName={configData[selectedConfig].name}
              updateConfig={updateConfigKey}
            />
          )}
          {!selectedConfig && (
            <div className="flex w-full">
              {!user ? (
                <h2 className="text-8xl text-center h-full w-full font-extrabold opacity-10">
                  Login to load configs.
                </h2>
              ) : (
                <h2 className="text-8xl text-center h-full w-full font-extrabold opacity-10">
                  Select a config category
                </h2>
              )}
            </div>
          )}
        </div>
      </div>
      <Tooltip
        content="open navigation sidebar"
        placement="left"
        color="primary"
      >
        <Button
          className="fixed bottom-10 right-10"
          color="primary"
          isIconOnly
          onClick={() => setSidebarOpen(!sideBarOpen)}
        >
          <FaBars />
        </Button>
      </Tooltip>
    </NextUIProvider>
  );
}

export default App;
